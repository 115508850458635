// DEV CHANGES ON 16 JANUARY 2025

import * as ActionToggle from "../../../core/action/toggle/index";
import * as PropertiesSearchDocument from "../../../core/action/SearchDocument/ActionPropertiesSearchResult";
import * as SearchDocument from "../../../core/action/SearchDocument/ActionSearchDocumentForm";

import React, { useState } from "react";
import { bindActionCreators, compose } from "redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { downloadBlob } from "../../../lib/utils";
import {
  downloadPackageCustomSearch,
  downloadPackageWaybill,
} from "../../../service/TrackingService";
import { getSearchDocument } from "../../../service/SearchDocumentService";
import ModalStatus from "../../../materials/ModalStatus";

import SearchDocumentComponent from "../../../components/Content/SearchDocument/SearchDocumentComponent";
import { connect } from "react-redux";
import { GetCustomerFeature } from "../../../service/authService";

interface IProps {
  actionSearchDocument: any;
  actionPropertiesSearchDocument: any;
  actionToggle: any;
  getSearchType: any;
  getSearchValue: any;
  getWaybill: any;
  getBarcode: any;
  getListResultSearch: any;
  getWorBSearch: any;
  getSearchResult: any;
  getNumberType: any;
  getImage: any;
  getSignature: any;
  getAccessContent: any;
  visible: any;
  typeToggle: any;
  isLoading: any;
  customerId: any;
}

export type SearchValueType = {
  typeSearch: string;
  filterDateType: string;
  datePeriode: any;
  valueSearch: any;
};

export const SearchDocumentContainer = (props: IProps) => {
  const { customerId } = props;
  const [initialValue, setInitialValue] = useState<SearchValueType>({
    typeSearch: "waybill",
    filterDateType: "Cycle",
    datePeriode: {
      waybill: [],
      barcode: [],
      NomerPolis: [],
      NomerRekening: [],
      NomerReferensi: [],
    },
    valueSearch: {
      waybill: "",
      barcode: "",
      NomerPolis: "",
      NomerRekening: "",
      NomerReferensi: "",
    },
  });
  const [listResultSearch, setListResultSearch] = useState<any[] | null>(null);
  const [notFoundValue, setNotFoundValue] = useState<string>("");

  const { data: dataCustomerFeature } = useQuery(
    ["customerFeature", customerId],
    GetCustomerFeature
  );

  const { mutate: mutateSearchDocument, isLoading: isLoadingSearch } =
    useMutation(getSearchDocument, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          setListResultSearch(res?.data || []);
          setNotFoundValue("");
        } else {
          ModalStatus({
            status: "error",
            title: "Terjadi Kesalahan",
            content: res.response.data.Message || "Hubungi Admin",
          });
          setNotFoundValue(initialValue.valueSearch.split("\n")[0]);
          setListResultSearch([]);
        }
      },
    });

  const { mutate: mutateDownloadCustom, isLoading: isLoadingDownloadCustom } =
    useMutation(downloadPackageCustomSearch, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(res.data, `${res.waybill}`);
        } else {
          ModalStatus({
            status: "error",
            title: "Terjadi Kesalahan",
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: "error",
          title: "Terjadi Kesalahan",
        });
      },
    });

  const { mutate: mutateDownloadWaybill, isLoading: isLoadingDownloadWaybill } =
    useMutation(downloadPackageWaybill, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(res.data, `${res.waybill}`);
        } else {
          ModalStatus({
            status: "error",
            title: "Terjadi Kesalahan",
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: "error",
          title: "Terjadi Kesalahan",
        });
      },
    });

  const handleChangeField = (name: string, value: any) => {
    setInitialValue((prevState) => {
      if (name === "valueSearch" || name === "datePeriode") {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            [prevState.typeSearch]: value,
          },
        };
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleModalPhoto = (event: any, type: any) => {
    props.actionPropertiesSearchDocument.setImageResult(event);
    props.actionToggle.changeModal();
    props.actionToggle.changeJenisToggle(type);
  };

  const handleModalSignature = (event: any, type: any) => {
    props.actionPropertiesSearchDocument.setSignatureResult(event);
    props.actionToggle.changeModal();
    props.actionToggle.changeJenisToggle(type);
  };

  const handleCloseModal = () => {
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
  };

  const handleSubmit = () => {
    const data = {
      typeSearch: initialValue.typeSearch,
      valueSearch: initialValue.valueSearch[initialValue.typeSearch]
        .split("\n")
        .join(";"),
      filterDateType: initialValue.filterDateType,
      startDate:
        initialValue?.datePeriode[initialValue.typeSearch].length >= 2
          ? initialValue.datePeriode[initialValue.typeSearch][0].format(
              "YYYYMMDD"
            )
          : null,
      endDate:
        initialValue?.datePeriode[initialValue.typeSearch].length >= 2
          ? initialValue.datePeriode[initialValue.typeSearch][1].format(
              "YYYYMMDD"
            )
          : null,
    };

    console.log("data", data);

    mutateSearchDocument(data);
  };

  return (
    <SearchDocumentComponent
      handleSubmit={handleSubmit}
      handleModalPhoto={handleModalPhoto}
      handleModalSignature={handleModalSignature}
      handleCloseModal={handleCloseModal}
      getImage={props.getImage}
      getSignature={props.getSignature}
      getAccessContent={props.getAccessContent}
      visible={props.visible}
      typeToggle={props.typeToggle}
      isLoading={props.isLoading || isLoadingSearch}
      isLoadingDownload={isLoadingDownloadCustom || isLoadingDownloadWaybill}
      onClickDownloadCustom={mutateDownloadCustom}
      onClickDownloadWaybill={mutateDownloadWaybill}
      initialValue={initialValue}
      handleChangeField={handleChangeField}
      listResultSearch={listResultSearch}
      notFoundValue={notFoundValue}
      isMonthFilter={dataCustomerFeature?.isMonthFilter}
      searchMonthFilter={dataCustomerFeature?.searchMonthFilter}
      showCourierName={dataCustomerFeature?.showCourierName}
    />
  );
};
const mapStateToProps = (state: any) => ({
  getSearchType: state.SearchDocument.searchForm.searchType,
  getSearchValue: state.SearchDocument.searchForm.searchValue,
  getWaybill: state.SearchDocument.searchForm.Waybill,
  getBarcode: state.SearchDocument.searchForm.Barcode,
  getListResultSearch: state.SearchDocument.listResultSearch,
  getWorBSearch: state.SearchDocument.propertiesSearchResult.WorBSearch,
  getSearchResult: state.SearchDocument.propertiesSearchResult.searchResult,
  getNumberType: state.SearchDocument.propertiesSearchResult.numberType,
  getImage: state.SearchDocument.propertiesSearchResult.image,
  getSignature: state.SearchDocument.propertiesSearchResult.signature,
  getAccessContent: state.Auth.AccessContent,
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  customerId: state.Auth.CustomerId,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionSearchDocument: bindActionCreators(SearchDocument, dispatch),
  actionPropertiesSearchDocument: bindActionCreators(
    PropertiesSearchDocument,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SearchDocumentContainer);
